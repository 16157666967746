<template>
	<div>
		<div class="main-box">
			<div class="main">
				<div class="redIcon">
					<div>
						<span v-show="status1 != 0">{{ status1 }}</span>
					</div>
					<div>
						<span v-show="status2 != 0">{{ status2 }}</span>
					</div>
					<div>
						<span v-show="status3 != 0">{{ status3 }}</span>
					</div>
					<div>
						<span v-show="status4 != 0">{{ status4 }}</span>
					</div>
				</div>
				<div class="tabs-nav">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="全部" name="first"> </el-tab-pane>
						<el-tab-pane label="未接单" name="second"> </el-tab-pane>
						<el-tab-pane label="进行中" name="ww"> </el-tab-pane>
						<el-tab-pane label="已完结" name="ee"> </el-tab-pane>
					</el-tabs>
					<div class="search-l">
						<input type="text" placeholder="搜索订单/订单号" v-model="value" />
						<div @click="search">搜索</div>
					</div>
				</div>
				<!-- 全部 -->
				<div v-show="current == 0">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									需求类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									发布日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">需求方账号</div>
						<div class="item132">需求名称</div>
						<div class="item118">需求预算</div>
						<div class="item118">合同金额</div>
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 需求状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item171">订单号</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.classText }}</div>
							<div class="item118">{{ item.sendTime }}</div>
							<div class="item85">{{ item.sendUid }}</div>
							<div class="item132">{{ item.name }}</div>
							<div class="item118">￥{{ item.sendMoneyBudget }}</div>
							<div class="item118">￥{{ item.winNeedMoney }}</div>
							<div class="item85 red">{{ stateKey[item.state] }}</div>
							<div class="item171">{{ item.id }}</div>
						</div>
					</div>
				</div>
				<!-- 待中标 -->
				<div v-show="current == 1">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									需求类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									发布日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">需求方账号</div>
						<div class="item132">需求名称</div>
						<div class="item118">需求预算</div>
						<div class="item85">订单号</div>
						<div class="item171">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.classText }}</div>
							<div class="item118">{{ item.sendTime }}</div>
							<div class="item85">{{ item.sendUid }}</div>
							<div class="item132">{{ item.name }}</div>
							<div class="item118">￥{{ item.sendMoneyBudget }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item171">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else>
								</font>
								<span class="blue" @click="readState(item)">查看进度</span>
								<el-popconfirm title="确认删除吗？"
									@confirm="remove(item.id)">
									<template #reference>
										 <!-- @click="remove(item.id)" -->
										<div style="color: #e40012">删除</div>
									</template>
								</el-popconfirm>
								
							</div>
						</div>
					</div>
				</div>
				<!-- 进行中 -->
				<div v-show="current == 2">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									需求类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									发布日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">需求方账号</div>
						<div class="item132">需求名称</div>
						<div class="item118">合同全额</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 进行状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">订单号</div>
						<div class="item85">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.classText }}</div>
							<div class="item118">{{ item.sendTime }}</div>
							<div class="item85">{{ item.sendUid }}</div>
							<div class="item132">{{ item.name }}</div>
							<div class="item118">￥{{ item.winNeedMoney }}</div>
							<div class="item118">{{ stateKey[item.state] }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item85 blue" style="display: flex">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else>
								</font>
								<div @click="readState(item)">查看进度</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 已完结 -->
				<div v-show="current == 3">
					<div class="cellter">
						<div class="item85">
							<el-dropdown trigger="click" placement="bottom" @command="getType">
								<span class="el-dropdown-link">
									需求类别<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in classList" :key="item.id">
										{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
								<span class="el-dropdown-link">
									发布日期<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">
										{{ item.title }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">需求方账号</div>
						<div class="item132">需求名称</div>
						<div class="item118">需求预算</div>
						<div class="item118">合同全额</div>
						<div class="item118">
							<el-dropdown trigger="click" placement="bottom">
								<span class="el-dropdown-link"> 完结状态 </span>
								<el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="item85">订单号</div>
						<div class="item85">操作</div>
					</div>
					<div class="cell-box" v-for="item in orderList" :key="item.id">
						<div class="cell">
							<div class="item85">{{ item.classText }}</div>
							<div class="item118">{{ item.sendTime }}</div>
							<div class="item85">{{ item.sendUid }}</div>
							<div class="item132">{{ item.name }}</div>
							<div class="item118">￥{{ item.sendMoneyBudget }}</div>
							<div class="item118">￥{{ item.winNeedMoney }}</div>
							<div class="item118 red">{{ stateKey[item.state] }}</div>
							<div class="item85">{{ item.id }}</div>
							<div class="item85 blue" style="display: flex">
								<font class="redBlock" v-if="item.redUid != null && item.redUid.includes(info.id)">
								</font>
								<font class="greyBlock" v-else>
								</font>
								<div @click="readState(item)">查看进度</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize"
					@current-change="changePage" :total="shopPage.totalRows">
				</el-pagination>
				<div class="total">共{{ shopPage.totalRows }}件/{{ shopPage.totalPage }}页</div>
				<div class="jump">
					跳转到
					<input type="number" v-model="shopNum" />
					页
				</div>
				<el-button style="
            background: #2ea7e0;
            color: #fff;
            width: 108px;
            height: 50px;
            font-size: 20px;
          " @click="goShopPage">前往</el-button>
			</div>
		</div>
		<Release v-if="dialogVisible" :orderInfo="orderInfo" :dialogVisible="dialogVisible" @close="close" />
	</div>
</template>

<script>
	import Release from "../../components/serve/release.vue";

	export default {
		metaInfo: {
			title: '企胖胖_用户信息管理中心_企胖胖', // set a title
			meta: [{
					name: 'description',
					content: '企胖胖_用户注册登陆管理中心快速设置，查询修改用户基础设置、我的订单、我的钱包、我的店铺、我的创作中心'
				},
				{
					name: 'keyWords',
					content: '企胖胖，用户管理中心，我的店铺，创作中心'
				},
			]
		},
		components: {
			Release
		},
		name: "QppOrder",
		data() {
			return {
				activeName: "first",
				current: 0, //当前下标
				value: "", //搜索内容
				show: false,
				orderInfo: {}, //选择的item传递内容
				stateKey: {
					//state的所有状态
					0: "正常",
					1: "初步创建", //初步创建
					2: "待支付意向金", //完善店铺详情 待支付保证金
					3: "已支付意金", //已支付保证金 待审核
					4: "已选择服务方",
					5: "等待对方操作",
					6: "待支付首款",
					7: "需求方支付完首款",
					8: "待验收",
					9: "验收工作成功",
					10: "已付尾款",
					11: "交易完成",
					12: "异常终止",
				},
				shopTime: [
					//时间筛选
					{
						id: 0,
						title: "3天内",
						day: 3
					},
					{
						id: 1,
						title: "一周内",
						day: 7
					},
					{
						id: 2,
						title: "一个月内",
						day: 30
					},
					{
						id: 3,
						title: "3个月内",
						day: 90
					},
					{
						id: 4,
						title: "半年内",
						day: 180
					},
					{
						id: 5,
						title: "1年内",
						day: 365
					},
					{
						id: 6,
						title: "3年内",
						day: 1095
					},
				],
				form: {
					//获取所有订单的参数
					state: 0, //跟着顺序走
					type: 0,
					day: 0,
					search: "",
					pageNo: 1,
				},
				shopNum: "", //分页跳转的页数
				classList: [], //所有分类列表
				orderList: [], //所有订单列表
				shopPage: {}, //需求总页数
				dialogVisible: false, //接单进度弹出层
				redInfo: "",
				status1: "",
				status2: "",
				status3: "",
				status4: "",
				info: ''
			};
		},
		mounted() {
			this.getRedNum();
			this.getInfo()
		},
		created() {
			this.getClass();
		},
		methods: {
			getInfo() {
				this.$util.post("/user/info").then((res) => {
					this.info = res.data;
				});
			},
			allData() {
				let allClassBig = {};
				for (var i in this.classList) {
					allClassBig[this.classList[i].id] = this.classList[i].name;
				}
				console.log(allClassBig);
				this.$util.post("/center/myOrder/release", this.form).then((res) => {
					res.data.forEach((item) => {
						item.classText = allClassBig[item.classifyId];
					});
					this.orderList = res.data;
					this.shopPage = res.pager;
				});
			},
			//获取我的发布红点统计
			getRedNum() {
				this.$util.post("/center/myOrder/releasePointSize").then((res) => {
					this.redInfo = res.data;
					this.status1 = this.redInfo.point1;
					this.status2 = this.redInfo.point2;
					this.status3 = this.redInfo.point3;
					this.status4 = this.redInfo.point4;
				});
			},
			// 获取所有分类
			getClass() {
				this.$util.post("/shop/allClassify").then((res) => {
					this.classList = res.data;
					this.allData();
				});
			},
			// 类型筛选
			getType(item) {
				this.form.type = item.id;
				this.allData();
			},
			// 搜索
			search() {
				(this.form = {
					state: 0, //跟着顺序走
					type: 0,
					day: 0,
					search: "",
					pageNo: 1,
				}),
				(this.form.search = this.value);
				this.allData();
			},
			// 跳转到指定的页数
			goShopPage() {
				this.form.pageNo = this.shopNum;
				this.allData();
			},
			// 页数改变是触发
			changePage(num) {
				this.form.pageNo = num;
				this.allData();
			},
			handleClick(tab) {
				(this.form = {
					state: 0, //跟着顺序走
					type: 0,
					day: 0,
					search: "",
					pageNo: 1,
				}),
				(this.current = tab.index);
				this.form.state = tab.index;
				this.allData();
			},
			// 时间筛选
			getShopTime(item) {
				this.form.day = item.day;
				this.allData();
				// this.getShopList()
			},
			// 查看进度
			readState(item) {
				// this.state = item.state
				if (item.state == 12) {
					this.$message.error("订单异常完结");
					if (
						item.redUid != "" &&
						item.redUid != null &&
						item.redUid != undefined
					) {
						this.$util
							.post("/demand/readeOrder", {
								orderId: item.id,
							})
							.then((res) => {
								console.log(res);
							});
					}
				} else {
					this.orderInfo = item;
					this.dialogVisible = true;
				}
			},
			// 删除订单
			remove(id) {
				this.$util
					.post("/center/myOrder/remove", {
						orderId: id,
					})
					.then((res) => {
						if (res.code == 200) {
							this.allData();
							this.$message.success("删除成功");
						} else {
							this.$message.error(res.message);
						}
					});
			},
			close() {
				// this.state = 0
				this.dialogVisible = false;
				// this.allData()
			},
		},
	};
</script>

<style lang="scss" scoped>
	.greyBlock {
		width: 10px;
		height: 10px;
		background: #999999;
		border-radius: 50%;
		display: block;
		margin-top: 5px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.redBlock {
		width: 10px;
		height: 10px;
		background: #e40012;
		border-radius: 50%;
		display: block;
		margin-top: 5px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.redIcon {
		display: flex;
		justify-content: flex-start;

		div {
			width: 100px;
			display: flex;
			justify-content: right;

			span {
				color: #ffffff;
				text-align: center;
				width: 20px;
				height: 20px;
				font-size: 12px;
				line-height: 20px;
				border-radius: 50%;
				background: #e40012;
			}
		}
	}

	.main-box {
		background: #f6f6f6;
		padding: 20px 0 40px;
	}

	.main {
		width: 1140px;
		padding: 30px;
		background: #fff;
		margin: 0 auto;
	}

	.tabs-nav {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e4e7ed;
		margin-top: -5px;
	}

	/deep/.el-tabs__item.is-active {
		color: #e40012;
	}

	/deep/.el-tabs__active-bar {
		background-color: #e40012 !important;
		width: 100px; // width: 80px !important;
	}

	/deep/.el-tabs__item {
		font-size: 22px;
		color: #666666;
		width: 100px;
		height: 50px;
		font-weight: bold;
		text-align: center;
	}

	/deep/.el-tabs__item:hover {
		color: #e40012;
	}

	/deep/.el-tabs__header {
		margin: 0;
	}

	.search-l {
		border: 1px solid #999999;
		display: flex;
		color: #fff;
		height: 40px;
		margin-right: 20px;
		font-size: 14px;

		input {
			flex: 1;
			color: #222;
			border: none;
			outline: none;
			height: 100%;
			padding: 0 20px;
		}

		div {
			padding: 10px 30px;
			background: #999999;
			cursor: pointer;
		}
	}

	.cellter {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		color: #222222;
		font-weight: bold;
		text-align: center;

		/deep/.el-dropdown {
			font-size: 16px;
			font-weight: bold;
			color: #222;
		}
	}

	.item85 {
		width: 85px;
		text-align: center;
	}

	.item118 {
		width: 118px;
		text-align: center;
	}

	.item132 {
		width: 132px;
		text-align: center;
	}

	.item75 {
		width: 75px;
		text-align: center;
	}

	.item85 {
		width: 85px;
		text-align: center;
	}

	.item90 {
		width: 90px;
		text-align: center;
	}

	.item171 {
		width: 171px;
		text-align: center;
		display: flex;
		justify-content: center;

		span {
			cursor: pointer;
			margin-right: 40px;
		}

		div {
			cursor: pointer;
		}
	}

	.item190 {
		width: 210px;
		text-align: center;
		display: flex;
		justify-content: center;

		span {
			cursor: pointer;
		}

		div {
			display: flex;
			margin-right: 10px;
			cursor: pointer;
		}
	}

	.red {
		color: #e40012;
	}

	.blue {
		cursor: pointer;
		color: #2ea7e0;
	}

	.pink {
		color: #fb487c;
	}

	.cell-box {
		padding: 30px 0 15px;
		border-bottom: 1px solid #eeeeee;
	}

	.cell {
		padding-bottom: 15px;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		color: #666666;
		text-align: center;
	}

	.cell-item {
		padding: 20px;
		background: #f6f6f6;

		.cell-list {
			display: flex;
			margin-bottom: 14px;
			justify-content: space-between;
			color: #666;
			font-size: 14px;
			text-align: center;
		}
	}

	.hui {
		color: #999999;
	}

	.pagination {
		width: 1200px;
		margin: 30px auto 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/deep/.el-pager li {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		border: 1px solid #2ea7e0;
		margin: 0 3px;
		color: #2ea7e0 !important;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		// border: 1px solid #2ea7e0;
		background: transparent;
		margin: 0 3px;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #fff !important;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 24px;
		color: #2ea7e0;
	}

	/deep/.el-pager li.active+li {
		border: 1px solid #2ea7e0;
	}

	.total {
		color: #2ea7e0;
		font-weight: bold;
		font-size: 16px;
	}

	.jump {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 0 20px 0 40px;
		color: #2ea7e0;

		input {
			width: 40px;
			height: 40px;
			border: 1px solid #0f6c97;
			font-size: 14px;
			color: #2ea7e0;
			outline: none;
			margin: 0 10px;
			border-radius: 4px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>
